import * as React from 'react'
import { RouterProps } from '@reach/router'

import Layout from '../components/layout'
import Listing from '../components/listing'

const PostsPage = ({ location }: RouterProps) => (
  <Layout location={location}>
    <Listing />
  </Layout>
)

export default PostsPage
