import * as React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import { PostsQueryData } from '../interfaces/PostsQuery.interface'
import { PostMeta } from './styled'

const LISTING_QUERY = graphql`
  query LISTING_QUERY {
    allMdx(
      limit: 100
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { draft: { ne: true } } }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            path
            title
            date(formatString: "MMMM DD, YYYY")
          }
          timeToRead
        }
      }
    }
  }
`

const Post = styled.article`
  /* box-shadow: 0 0.3rem 1rem rgba(25, 17, 34, 0.05); */
  padding: 0.5rem;
  border-radius: 0;
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--line-color);

  :last-child {
    border-bottom: none;
  }

  a {
    text-decoration: none;
    transition: all 0.25s linear;
    background-clip: text;
    background-image: linear-gradient(
      to right,
      var(--primary-color),
      var(--secondary-color)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    border-bottom: 1px dashed transparent;

    :hover {
      border-bottom-color: var(--line-color);
    }
  }

  h2 {
    margin-bottom: 0.4rem;
  }

  p {
    font-size: 0.9rem;
  }
`

const Listing = () => (
  <StaticQuery
    query={LISTING_QUERY}
    render={({ allMdx }: PostsQueryData) =>
      allMdx.edges.map(({ node }) => {
        const { path, title, date } = node.frontmatter

        return (
          <Post key={path}>
            <h2>
              <Link to={`/posts${path}`}>{title}</Link>
            </h2>
            <PostMeta>
              {date} · {node.timeToRead} min
            </PostMeta>
            <p>{node.excerpt}</p>
          </Post>
        )
      })
    }
  />
)

export default Listing
